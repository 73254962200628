import * as Sentry from "@sentry/react";
import React from "react";
import CustomErrorFallback from "../../components/CustomErrorFallback";

export default class LocalReporter {
	transactionId: string;

	constructor() {
		this.transactionId = this.generateTransactionId();
	}

	generateTransactionId(seed?: string) {
		this.transactionId = btoa([seed, Math.random(), Date.now()].join("-"));
		console.log("LocalReporter transactionId", this.transactionId);
		return this.transactionId;
	}

	notify(message: string, { context }: { context?: Record<string, any> } = {}) {
		console.log("LocalReporter notify", message, context);
	}

	setContext(context: Record<string, any>) {
		console.log("LocalReporter setContext", context);
	}

	resetContext() {
		console.log("LocalReporter resetContext");
	}

	// here we are using the sentry ErrorBonudry locally. it does not report, but gives the same UX / UI as on test / production.
	errorBoundary({
		children,
	}: { children: React.ReactNode }): React.ReactElement {
		return (
			<Sentry.ErrorBoundary fallback={CustomErrorFallback}>
				{children}
			</Sentry.ErrorBoundary>
		);
	}
}
