import { gql } from "@apollo/client";
import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ScrollToTop } from "./components/ScrollToTop";

import styles from "./App.module.css";
import AppMain from "./AppMain";
import { VerticalToHorizontalLayout } from "./components/Layout";
import SecurityContext from "./components/SecurityContext";
import Spinner from "./components/Spinner";
import SystemNotificationToast from "./components/SystemNotificationToast";
import i18n from "./config/initializers/i18n";
import "./globals.css";
import logoUrl from "./images/logo.svg";
import MainNavigation from "./MainNavigation/MainNavigation";
import "./palette.css";
import "./reset.css";

import { devLogger, publicUrl, voimaVersion } from "./config/env";

import AppContext, { TFeatureFlag } from "./AppContext";

import { AccountInfo, EventType } from "@azure/msal-browser";
import { loginRequest, PublicClientApplication } from "./config/auth";
import { CurrentUser, FeatureFlagEnum, SystemNotification } from "./graphql";

import { MatomoProvider } from "@datapunt/matomo-tracker-react";
import actionReporter from "~/config/initializers/actionReporter";
import { useClientProxy } from "~/lib/ClientProxy";
import { useMatomoClient } from "~/lib/MatomoClient";
import NewVoimaVersionToast from "./components/NewVoimaVersionToast";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = lazy(() => import("./components/Login"));

const hostUrl = publicUrl ? new URL(publicUrl) : null;
if (hostUrl && hostUrl.hostname !== window.location.hostname) {
	window.location.assign(
		`${hostUrl.origin}${window.location.pathname}${window.location.search}`,
	);
}

export const storeRedirectUri = () => {
	const pathname = window.location.pathname;
	if (pathname.match(/^\/login/)) return;

	devLogger.debug("storing redirect uri", pathname);
	localStorage.setItem("redirectUri", pathname);
};

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      adUnitCode
      roles {
        superadmin
        referenceGroupAdmin
        documentationLibraryAdmin
        referenceTemplateAdmin
        frameAgreementEditor
        assistant
        manager
        projectManager
      }
      latestSystemNotification {
        id
        body
        author {
          name
        }
        publishedAt
      }
      policies {
        referenceGroup {
          index
        }
        documentationLibrary {
          index
          searchEnabled
        }
        worksite {
          index
          create
        }
        reclamation {
          index
          updateClaimAmountInAccounting
        }
        frameAgreement {
          index
          create
        }
      }
      featureFlags {
        name
        enabled
      }
      voimaVersion
    }
  }
`;

const App = ({ authApp }: { authApp: PublicClientApplication }) => {
	const clientProxy = useClientProxy();
	const matomoClient = useMatomoClient();

	const [loading, setLoading] = useState(true);

	const insideIframe = () => {
		return window.location !== window.parent.location;
	};
	if (insideIframe()) return;

	const setActiveAccount = (account: AccountInfo) => {
		devLogger.log("authApp setting active account to", account);
		authApp.setActiveAccount(account);
		setAccount(account);
		acquireToken();
	};

	useEffect(() => {
		authApp.enableAccountStorageEvents();
		authApp.addEventCallback((event) => {
			devLogger.log("authApp event", event);
			if (event.eventType === EventType.HANDLE_REDIRECT_START) {
				setLoading(true);
			}
			if (event.eventType === EventType.HANDLE_REDIRECT_END) {
				setLoading(false);
			}
			if (
				(event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
					event.eventType === EventType.LOGIN_SUCCESS) &&
				event.payload
			) {
				devLogger.log("authApp acquire token success", event.payload);

				const accessToken = event.payload["accessToken"] as string;
				setAccessToken(accessToken);

				const account = event.payload["account"] as AccountInfo;
				setActiveAccount(account);
			}
		});
		acquireToken();
	}, []);

	const [nextVoimaVersion, setNextVoimaVersion] = useState<
		string | undefined
	>();
	const isNextVoimaVersionAvailable =
		nextVoimaVersion && voimaVersion !== nextVoimaVersion;

	const [initialTokenFetchDone, setInitialTokenFetchDone] = useState(false);
	const [accessToken, setAccessToken] = useState<string>();
	const [currentUser, setCurrentUser] = useState<CurrentUser>();
	const [account, setAccount] = useState<AccountInfo>();
	const [userInfo, setUserInfo] = useState();
	const [userGroups, setUserGroups] = useState([]);
	useEffect(() => {
		if (accessToken) {
			clientProxy.setAccessToken(accessToken);
			fetchUserInfo().then(() => {
				setInitialTokenFetchDone(true);
			});
		}
	}, [accessToken]);
	useEffect(() => {
		if (accessToken && initialTokenFetchDone) {
			const redirectUri = localStorage.getItem("redirectUri");
			if (redirectUri) {
				setTimeout(() => {
					devLogger.debug("redirectUrl", redirectUri);
					localStorage.removeItem("redirectUri");
					if (redirectUri.startsWith("/")) {
						window.location.assign(redirectUri);
					}
				}, 500);
			} else {
				fetchCurrentUser();
			}
		}
	}, [accessToken, initialTokenFetchDone]);
	useEffect(() => {
		if (currentUser && userInfo) {
			actionReporter.setContext({
				voimaUserId: currentUser.id,
				voimaUserAdmin: currentUser.roles.superadmin,
				o365AccountId: userInfo.id,
				voimaVersion: voimaVersion,
			});
			setLoading(false);
		} else {
			actionReporter.resetContext();
		}
	}, [currentUser, userInfo]);

	const [featureFlags, setFeatureFlags] = useState({});

	const [systemNotification, setSystemNotification] =
		useState<SystemNotification | null>(null);
	const [isSystemNotificationVisible, setIsSystemNotificationVisible] =
		useState(false);

	const [policies, setPolicies] = useState();
	const [superadmin, setSuperadmin] = useState(false);
	const [documentationLibraryAdmin, setDocumentationLibraryAdmin] =
		useState(false);

	const [ready, setReady] = useState(false);

	useMemo(() => {
		return setInterval(
			() => {
				acquireToken().then(() => {
					fetchCurrentUser();
				});
			},
			5 * 60 * 1000,
		);
	}, []);

	const resetSession = () => {
		storeRedirectUri();

		actionReporter.resetContext();
		setAccessToken(undefined);
		setAccount(undefined);
		setCurrentUser(undefined);
		setUserInfo(undefined);
		setUserGroups([]);
		setPolicies(undefined);
		setSuperadmin(false);
		setDocumentationLibraryAdmin(false);
		setFeatureFlags({});

		authApp.clearCache();
	};

	/***
	 * If authentication fails via apollo gql returned error from the server, for example `NOT_AUTHENTICATED` it is
	 * then fired upstream here. We catch it here and show a toast to the user and reset the session.
	 * Function used so we get same behavior in both apollo client and rest api client.
	 */
	const handleAuthenticationError = () => {
		toast.error("You have been logged out, redirecting to login page");
		resetSession();
	};

	clientProxy.apolloClientProxy.onAuthenticationError =
		handleAuthenticationError;
	clientProxy.restApiClient.onAuthenticationError = handleAuthenticationError;

	const logOutRedirect = () => {
		authApp.logoutRedirect();
	};

	const getSystemNotificationKey = (notification) => {
		return `SystemNotification-${notification.id}`;
	};

	const shouldShowSystemNotification = (notification) => {
		return !localStorage.getItem(getSystemNotificationKey(notification));
	};

	const readSystemNotification = (notification) => {
		localStorage.setItem(getSystemNotificationKey(notification), "1");
		setIsSystemNotificationVisible(false);
	};

	const fetchCurrentUser = () => {
		devLogger.debug("fetchCurrentUser call");
		return clientProxy.apolloClient.query({ query: GET_CURRENT_USER }).then(
			(response) => {
				devLogger.debug("fetchCurrentUser response", response.data);

				const currentUserResponse = response.data?.currentUser as CurrentUser;

				if (currentUserResponse) {
					const {
						adUnitCode,
						roles,
						latestSystemNotification,
						policies,
						featureFlags,
					} = currentUserResponse;
					const { documentationLibraryAdmin, superadmin } = roles || {};
					const userRoles = roles
						? Object.entries(roles)
								.filter(([_id, val]) => val === true)
								.map(([id]) => id)
						: [];
					const systemNotification = latestSystemNotification
						? {
								...latestSystemNotification,
								authorName: latestSystemNotification.author?.name,
							}
						: null;

					AppContext.setFeatureFlags(
						featureFlags.reduce<Record<string, TFeatureFlag>>(
							(acc, { name, enabled }) => {
								acc[name] = { name, enabled };
								return acc;
							},
							{},
						),
					);

					setReady(true);
					setCurrentUser(currentUserResponse);
					setSuperadmin(superadmin);
					setDocumentationLibraryAdmin(documentationLibraryAdmin);
					setSystemNotification(systemNotification);
					setIsSystemNotificationVisible(
						systemNotification
							? shouldShowSystemNotification(systemNotification)
							: false,
					);
					setPolicies(policies);
					setFeatureFlags(AppContext.featureFlags);
					setNextVoimaVersion(currentUserResponse.voimaVersion);

					matomoClient.setCostCenter(adUnitCode || "null");
					matomoClient.setRoles(userRoles);
				} else {
					devLogger.error("fetchCurrentUser response is null");
				}
			},
			(error) => {
				console.error("fetchCurrentUser error", error);
				actionReporter.notify(error);
			},
		);
	};

	const fetchUserGroups = () => {
		return clientProxy.msGraphClientProxy.userGroups.then(({ value }) => {
			devLogger.debug("fetchUserGroups response", value);
			setUserGroups(value);
		});
	};

	const fetchUserInfo = () => {
		return clientProxy.msGraphClientProxy.userInfo
			.then((data) => {
				devLogger.debug("fetchUserInfo response", data);
				setUserInfo(data);
			})
			.then(fetchUserGroups);
	};

	const acquireToken = async (): Promise<string | undefined> => {
		const activeAccount = authApp.getActiveAccount();
		devLogger.log("authApp active account", activeAccount);
		if (activeAccount) {
			return authApp.acquireTokenSilent(loginRequest).then((result) => {
				setAccessToken(result.accessToken);
				setAccount(activeAccount);
				return result.accessToken;
			});
		} else {
			const allAccounts = authApp.getAllAccounts();
			if (allAccounts.length > 0) {
				const account = allAccounts[0];
				// NOTE: might hit circular dependency
				setActiveAccount(account);
			}
		}
	};

	const [errors, setErrors] = useState<Array<{ id: string; message: string }>>(
		[],
	);

	const handleReset = () => {
		// Add your reset logic here
		setErrors([]);
	};

	const handleMoreDetails = () => {
		// Add logic to open the ErrorFeedback dialog here
		console.log("Opening more details dialog...");
	};

	const handleDismiss = () => {
		setErrors([]);
	};

	return (
		<Router>
			<ScrollToTop />
			<SecurityContext.Provider
				value={{
					accessToken,
					ready,
					initialTokenFetchDone,
					canEdit: false,
					logOutRedirect,
					setSystemNotification,
					featureFlags,
					account,
					userInfo,
					userGroups,
					currentUser,
					policies,
					superadmin,
					documentationLibraryAdmin,
				}}
			>
				<MatomoProvider value={matomoClient.matomoInstance}>
					{loading ? (
						<main
							className={
								"flexY alignCenter justifyCenter childMarginsY maxWidth " +
								styles.login
							}
						>
							<Spinner>{String(i18n.t("General.loadingLogin"))}</Spinner>
						</main>
					) : !account ? (
						<main
							className={
								"flexY alignCenter justifyCenter childMarginsY maxWidth " +
								styles.login
							}
						>
							<h1>
								<img
									style={{ height: "4.5rem" }}
									src={logoUrl}
									alt="Sitowise Voima"
								/>
							</h1>
							<hr />
							<Suspense
								fallback={
									<Spinner>{String(i18n.t("General.loadingLogin"))}</Spinner>
								}
							>
								<Login signIn={() => authApp.loginRedirect(loginRequest)} />
							</Suspense>
						</main>
					) : !ready ? (
						<main
							className={
								"flexY alignCenter justifyCenter childMarginsY maxWidth " +
								styles.login
							}
						>
							<Spinner>{String(i18n.t("General.loadingLogin"))}</Spinner>
						</main>
					) : (
						<div className={styles.appContainer}>
							{isNextVoimaVersionAvailable ? <NewVoimaVersionToast /> : null}

							{isSystemNotificationVisible ? (
								<SystemNotificationToast
									notification={systemNotification}
									readNotification={(notification) => {
										readSystemNotification(notification);
									}}
								/>
							) : null}

							<VerticalToHorizontalLayout
								className={[styles.masthead, "justify"].join(" ")}
							>
								<MainNavigation
									logOut={logOutRedirect}
									userInfo={userInfo}
									superadmin={superadmin}
									policies={policies}
									documentationLibraryAdmin={documentationLibraryAdmin}
									isSystemNotificationVisible={isSystemNotificationVisible}
								/>
							</VerticalToHorizontalLayout>

							<AppMain
								policies={policies}
								admins={{
									superadmin: superadmin,
									documentationLibraryAdmin: documentationLibraryAdmin,
								}}
							/>
						</div>
					)}
				</MatomoProvider>
			</SecurityContext.Provider>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={true}
				closeOnClick={true}
				pauseOnHover={true}
				draggable={true}
				progress={undefined}
			/>
		</Router>
	);
};
export default App;
