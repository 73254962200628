import * as Sentry from "@sentry/react";
import { sentryDsn, sentryEnv, sentryRelease } from "../env";

const sentry = Sentry.init({
  dsn: sentryDsn,
  environment: sentryEnv,
  release: sentryRelease,
  ignoreErrors: [
    /InteractionRequiredAuthError/,
    /BrowserAuthError/,
    /ClientAuthError/,
  ],
  integrations: [
    Sentry.browserTracingIntegration(),
    // Sentry.reactRouterV5BrowserTracingIntegration({
    //   history,
    //   routes,
    //   matchPath
    // }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.01,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});

export default sentry;
