import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { defaultLanguage } from "~/config/i18n";
import { getLangAsync } from "~/helpers";
import { isDevelopment } from "~/config/env";
import { I18N_ENABLED } from "~/globals";

import fi from "~/localization/fi.json";
import en from "~/localization/en.json";
import sv from "~/localization/sv.json";

const languageDetector = {
  type: "languageDetector",
  async: true,
  detect: (callback) => {
    if (!I18N_ENABLED) {
            console.warn("I18n globally disabled!");
      callback(defaultLanguage);
      return;
    }

    const browserLocale = defaultLanguage;
    getLangAsync()
      .then((lang) => callback(lang || browserLocale))
      .catch(() => callback(defaultLanguage));
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    debug: isDevelopment,
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      fi: { translation: fi },
      en: { translation: en },
      sv: { translation: sv },
    },
  });

export default i18n;
